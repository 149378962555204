<template>
    <div>
        <div class="container">
            <div class="row mt-5 mb-5">
                <div class="col-12 text-center">
                    <h1 style="font-size: 5rem;" class="pb-4">
                        404
                    </h1>
                    <p>
                        {{ $t('message.page404.text') }}.
                    </p>
                    <p>
                        <router-link :to="{name:'main', params: {lang:'pt'}}" style="text-decoration: underline">
                            {{ $t('message.page404.link') }}
                        </router-link>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name       : 'NotFound',
        components : {},
        data       : function () {
            return {};
        },
        methods    : {}
    };
</script>
